import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
  HttpResponse,
} from '@angular/common/http';
import { EMPTY, Observable, TimeoutError, throwError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';
import { AppRoutingService } from '../routing/app-routing.service';
import { ErrorBase } from '../models/error.model';
import { AuthService } from 'src/app/features/auth/auth.service';
import { AppLoadingOverlayService } from '@shared/components/app-loading-overlay/app-loading-overlay.service';
import { environment } from '@config/environments/environment';
import _ from 'lodash';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { TranslocoService } from '@ngneat/transloco';
import { API_VERSIONs } from '@config/api.config';

//DOC: https://angular.io/guide/http-intercept-requests-and-responses
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: AppRoutingService,
    private toastr: ToastrService,
    private readonly authService: AuthService,
    private readonly loadingOverlay: AppLoadingOverlayService,
    private readonly modalService: NgbModal,
    private translate: TranslocoService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * PIPE: [---> handleGlobalError ---> mappingHttpErrorResponseToErrorBase ----> ]
     */
    const API_VERSION_HEADER_KEY = 'X-Api-Version';
    return next.handle(request).pipe(
      timeout(this.getRequestTimeOutFromEnvironment()),
      /**
       * Catch HttpErrorResponse then use status code to handle global error.
       * Nếu đã bắt được các lỗi global thì return EMPTY (Event rỗng -> Chuyển đến complete)
       * Ngược lại chuyển HttpErrorResponse thành ErrorBase và throwError cho các service bên trong xử lý.
       */
      catchError((error: HttpErrorResponse | TimeoutError) => {
        if (error instanceof TimeoutError) {
          this.loadingOverlay.setState({ isLoading: false });
          return EMPTY;
        }

        const globalErrorHandled = this.handleHttpError(error);
        if (globalErrorHandled === EMPTY) {
          this.loadingOverlay.setState({ isLoading: false });
        }

        const apiVersion = error.headers.get(API_VERSION_HEADER_KEY);
        return throwError(() => new ErrorBase(error, apiVersion));
      })
    );
  }

  getRequestTimeOutFromEnvironment = () => {
    const defaultRequestTimeOut = 30000; // 30s

    const requestTimeOut =
      environment?.application?.http?.timeout ?? defaultRequestTimeOut;
    const minRequestTimeOut = 15000; // 30s
    if (
      requestTimeOut &&
      requestTimeOut > defaultRequestTimeOut &&
      requestTimeOut < minRequestTimeOut
    ) {
      return defaultRequestTimeOut;
    }

    return requestTimeOut;
  };
  /**
   * Xử lý lỗi từ phản hồi HTTP và thực hiện các hành động tùy thuộc vào mã trạng thái của lỗi.
   * @param error - Đối tượng chứa thông tin lỗi từ phản hồi HTTP.
   * @returns Đối tượng lỗi ban đầu được truyền vào.
   */
  private handleHttpError = (error: HttpErrorResponse) => {
    switch (error.status) {
      /**
       * Case 0: Trường hợp không thể kết nối với API server
       * Title: Không thể kết nối đến máy chủ:
       * Message: Không thể thiết lập kết nối tới máy chủ. Vui lòng kiểm tra kết nối mạng của bạn và thử lại.
       */
      case 0:
        this.toastr.error(
          this.translate.translate('error.0.message'),
          this.translate.translate('error.0.title')
        );
        //this.notify.showNotificationAlert('error', 'error.0.title', 'error.0.message', true);
        return EMPTY;
      // /**
      //  * Case 404 : HttpStatusCode.NotFound - Không tìm thấy API của API server
      //  * Title: Chức năng đang dừng hoạt động
      //  * Messsage: Chức năng mà bạn đang cố gắng truy cập hiện đang dừng hoạt động.
      //  */
      // case HttpStatusCode.NotFound:
      //   this.toastr.error(
      //     this.translate.translate('error.404Api.message'),
      //     this.translate.translate('error.404Api.title')
      //   );
      //   //this.notify.showNotificationAlert('error', 'error.404Api.title', 'error.404Api.message', true);
      //   return EMPTY;

      /**
       * Case 408 : HttpStatusCode.RequestTimeout - Hết thời gian truy cập
       * Title: Yêu cầu hết thời gian
       * Messsage: Yêu cầu của bạn đã vượt quá thời gian chờ tối đa của máy chủ.
       */
      case HttpStatusCode.RequestTimeout:
        this.toastr.error(
          this.translate.translate('error.408.message'),
          this.translate.translate('error.408.title')
        );
        //this.notify.showNotificationAlert('error', 'error.408.title', 'error.408.message', true);
        return EMPTY;

      /**
       * Case 401 : HttpStatusCode.Unauthorized - Chưa đăng nhập:
       * Nếu chưa đăng nhập sẽ chuyển trang sang trang đăng nhập.
       */
      case HttpStatusCode.Unauthorized:
        // TODO: Every when use modal need to dismiss them when unauthorize:
        // WARNGING: How to dismiss all type of model ??
        this.modalService.dismissAll();
        this.router.navigateToLoginPage();
        this.toastr.info(
          this.translate.translate('error.invalidToken.desc'),
          this.translate.translate('error.invalidToken.title')
        );
        //this.notify.showNotificationAlert('info', 'error.invalidToken.title', 'error.invalidToken.desc', true)
        return EMPTY;

      /**
       * Case 500 : HttpStatusCode.InternalServerError - Lỗi do máy chủ API gặp sự cố.
       * Chuyển sang trang Internal Server Error
       */
      case HttpStatusCode.InternalServerError:
        // this.router.navigateToInternalServerErrorPage();
        return EMPTY;

      /**
       * Case 403 : HttpStatusCode.Forbidden - Không được cấp quyền truy cập
       * - Chuyển trang 403 nếu không có quyền
       * - Chuyển trang đăng nhập nếu chưa đăng nhập
       */
      case HttpStatusCode.Forbidden:
        const haveNoAuthResponse = { message: 'NOT_ALLOW' };
        if (_.isEqual(error?.error, haveNoAuthResponse)) {
          if (!this.authService.isLoggedIn()) {
            this.router.navigateToLoginPage();
          } else {
            this.router.navigateToUnauthorizedPage();
          }
        }
        return EMPTY;

      default:
        break;
    }

    return error;
  };
}
