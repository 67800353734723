import { AppComponent } from './app/app.component';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { ToastrModule } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.module';
import { HttpRequestInterceptor } from '@core/interceptors/request.interceptor';
import { HttpResponseInterceptor } from '@core/interceptors/response.interceptor';
import { environment } from '@config/environments/environment';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@shared/components/lib-material/paginator';
import { TranslocoRootModule } from '@shared/components/zaa-transloco-root/transloco-root.module';
import { AppRoutingModule } from './app/app.routing.module';
import { TuiRootModule } from '@taiga-ui/core';

const APP_BOOTSTRAP_CONFIG = {
  providers: [
    importProvidersFrom([
      // Core lib modules
      BrowserModule,
      HttpClientModule,
      AppRoutingModule,

      // Global modules
      TranslocoRootModule,
      ToastrModule.forRoot({ timeOut: 3000 }),
      TuiRootModule,
    ]),
    provideAnimations(),
    // Interceptor http provider
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },

    // Trang mạc định co material paging
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue:
        environment.application.ui.pagingation as MatPaginatorDefaultOptions
    }
  ],
}

bootstrapApplication(AppComponent, APP_BOOTSTRAP_CONFIG)
  .then(() => {
    if (isDevMode()) {
      console.group(`Bootstrap Angular application success`)
    }
  })
  .catch((err) => {
    console.error(`Error when bootstrap application: ${err.message}`);
  })
