import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/config/environments/environment';
import _ from 'lodash';
import { AuthService } from '@features/auth/auth.service';

/**
 * Lớp HttpRequestInterceptor là một interceptor cho HTTP request,
 * dùng để thêm thông tin header vào request trước khi gửi đi.
 */
@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) { }
  /**
   * Phương thức intercept được gọi mỗi khi có một HTTP request sắp được gửi đi.
   * Phương thức này sẽ thêm các header cần thiết vào request và trả về request đã được chỉnh sửa.
   *
   * @param request HttpRequest<any> - Đối tượng request ban đầu.
   * @param next HttpHandler - Bộ xử lý HTTP tiếp theo trong chuỗi xử lý.
   * @returns Observable<HttpEvent<any>> - Một Observable chứa các sự kiện HTTP.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    const appRequest = request.clone({
      setHeaders: this.getApplicationHeaders()
    });

    return next.handle(appRequest);
  }

  /**
   * Phương thức private getApplicationHeaders được sử dụng để lấy các thông tin header
   * cần thiết từ localStorage và environment để đưa vào request.
   *
   * @returns {Object} - Một đối tượng chứa các thông tin header.
   * @private
   */
  private getApplicationHeaders = (): any => {
    const { app } = environment.application.metadata;
    return {
      Authorization: this.authService.buildTokenByJWTPrefix(),
      ['App-Key']: app
    }
  }
}
